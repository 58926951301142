.carouselContainer {
  position: relative;
  width: 100%;
  min-height: 60vh; /* Altura mínima ajustable */
  overflow: hidden;
}

.imageWrapper {
  display: flex;
  transition: transform 1s ease-in-out;
}

.carouselImage {
  width: 100%;
  height: 50vh; /* Asegura que todas las imágenes tengan la misma altura */
  object-fit: cover; /* Ajusta la imagen al contenedor */
}

/* Botones de navegación */
.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.navButton.left {
  left: 10px;
}

.navButton.right {
  right: 10px;
}

.imageContainer {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s ease-in-out;
}
