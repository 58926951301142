/* Estilos generales para el componente */
.whatsapp-chat {
  width: 100%; /* Ancho completo en versión móvil */
  max-width: 550px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease;
  font-family: Arial, sans-serif;
}

.whatsapp-header {
  display: flex;
  align-items: center;
  background-color: #1a393c;
  color: white;
  padding: 15px;
  cursor: pointer;
}

.whatsapp-logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
}

.whatsapp-header-text {
  flex-grow: 1;
}

.whatsapp-title {
  font-size: 18px;
  font-weight: bold;
}

.whatsapp-caption {
  font-size: 14px;
  opacity: 0.8;
}

.whatsapp-body {
  background-color: #e5ddd5;
  padding: 15px;
  max-height: calc(100vh - 80px); /* Para ocupar toda la altura disponible menos el header */
  overflow-y: auto;
}

.whatsapp-message {
  background-color: white;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.whatsapp-questions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.whatsapp-question-btn {
  background-color: #8eb937;
  color: white;
  border: none;
  padding: 12px 15px;
  border-radius: 20px;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.whatsapp-question-btn:hover {
  background-color: #7aa32e;
}

.whatsapp-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.whatsapp-response {
  margin-top: 15px;
}

.whatsapp-question {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  color: #075e54;
}

.whatsapp-reply {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.whatsapp-reply-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.whatsapp-reply-item:last-child {
  border-bottom: none;
}

.whatsapp-reply-title {
  font-weight: bold;
  color: #075e54;
  margin-bottom: 10px;
}

.whatsapp-reply-value {
  color: #128c7e;
}

.whatsapp-footer {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.whatsapp-input {
  flex-grow: 1;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 14px;
}

.whatsapp-send-btn {
  background-color: #8eb937;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.whatsapp-send-btn:hover {
  background-color: #7aa32e;
}

/* Estilos específicos para pantallas móviles */
@media (max-width: 768px) {
  .whatsapp-chat {
    width: 100%; /* Ancho completo */
    max-width: 100%; /* Sin límite en mobile */
    height: 100vh; /* Altura total de la pantalla */
    border-radius: 0; /* Sin bordes redondeados en mobile */
  }

  .whatsapp-body {
    max-height: calc(100vh - 80px); /* Ajusta la altura para el cuerpo del chat */
    padding: 10px; /* Reducir padding en mobile */
  }

  .whatsapp-question-btn {
    font-size: 14px; /* Ajustar tamaño de fuente */
    padding: 10px; /* Ajustar padding */
  }

  .whatsapp-reply {
    padding: 10px; /* Reducir padding en mobile */
  }
}
