@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
    margin: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  .urbanizacion-navbar {
    margin-bottom: 16px;
    color: #246f74;
    font-family: "Inter-Regular", Helvetica;
    font-size: 28px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .urbanizacion-toolbar {
    display: flex;
    justify-content: center;
  }
  
  .urbanizacion-logo {
    height: 115px;
    width: auto;
    margin-top: 1%;
    
  }

  .urbanizacion-nav {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    
  }
  
  .urbanizacion-navlink {
    margin: 0 12px;
  }
  .urbanizacion-image-container {
    position: relative;
    text-align: center;
  }
  
  .urbanizacion-header-image {
    width: 115vw;
    height: 50vh;
    margin-bottom: 16px;
    top:348.59px;
    margin-left:-10%;
  }
  .urbanizacion-overlay-text {
    position: absolute;
    top: 50%;
    left: 10%; /* Mantén el valor de "left" para controlar qué tan lejos del borde izquierdo lo deseas */
    transform: translateY(-50%); /* Solo ajustamos la posición vertical */
    color: white;
    font-family: 'inter';
   

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    text-align: left; /* Alinea el texto hacia la izquierda */
    font-size: '60px'; 
    font-weight: 'bold', 
   
  }
  
  .urbanizacion-section {
    padding: 16px 0;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  
  .urbanizacion-content-image {
    width: 714.29px;
    height: 616.51px;
    top:1173.5px;
    Left:1030.71px;
  }
  .urbanizacion-content-image2 {
    width: 600.29px;
    height: auto;
    top:1173.5px;
    margin-right: 7%;
    Left:1030.71px;
  }
  .urbanizacion-comparison-section {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 32px;
    background-color: #e0f7fa;
  }
  
  .urbanizacion-comparison-title {
    margin-bottom: 16px;
    text-align: center;
  }
  
  .urbanizacion-comparison-grid {
    display: flex;
    justify-content: space-around;
  }
  
  .urbanizacion-comparison-item {
    width: 45%;
    padding: 16px;
    background-color: #ffffff;
  }
  
  .urbanizacion-footer {
    margin-top: 32px;
    padding: 16px;
    text-align: center;
    background-color: #e0e0e0;
  }
  /* src/App.css */

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #326B6B;
  color: white;
  padding: 20px;
}

.comparativa-container {
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  color: white;
}

.subtitulo {
  font-weight: normal;
}

.comparativa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.urbanizacion, .barrio-cerrado {
  flex: 1;
  padding: 10px;
}

.urbanizacion h3, .barrio-cerrado h3 {
  color: #2D623D;
}

.versus {
  flex: 0;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.arrows {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.arrow {
  font-size: 24px;
  color: white;
}

.arrow.left {
  transform: rotate(-45deg);
}

.arrow.right {
  transform: rotate(45deg);
}

/* src/App.css */

.App3 {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #f4f4f4;
  color: #333;
  padding: 20px;
}

.urbanizacion-descripcion-container {
  display: flex;
  flex-direction: row; /* Asegura que los elementos estén en fila */
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.urbanizacion-texto {
  flex: 1;
  padding-right: 20px;
  text-align: left; /* Alinea el texto a la izquierda */
}

.urbanizacion-texto p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.urbanizacion-imagen {
  flex: 0;
  max-width: 400px;
}

.urbanizacion-imagen img {
  max-width: 100%;
  border-radius: 10px;
}

@font-face {
  font-family: 'Human Nature - Demo';
  src: url('../mapas/HumanNature.ttf') format('truetype');
}