/* loginComponent.css */
.login-page {
  display: flex;
  height: 100vh;
}

.image-container {
  flex: 1;
  background-color: #f0f0f0;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #214138; /* Color de fondo de la tarjeta de login */
  position: relative;
}

.logo {
  width: 50%; /* La mitad del tamaño del contenedor de login */
  margin-bottom: 20px; /* Separación entre el logo y la tarjeta */
}

.login-card {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
  text-align: center; /* Asegura que todo esté centrado horizontalmente */
}

h2 {
  margin-bottom: 20px;
  color: #214138;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #214138;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}
